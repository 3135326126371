/** @jsx jsx */
import { jsx, Box, Container, Flex, Message } from 'theme-ui'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { RichText } from 'prismic-reactjs'
import { get } from 'lodash'

import {
  Layout,
  Main,
  Section,
} from '@giraldomac/gatsby-theme-mindmerge/src/components/layout'

const Post = (props) => {
  const edges = get(props.data, 'prismic.allPosts.edges', [])
  const data = get(edges, '0.node')
  const image = `/metaimage.png`

  if (!data) {
    return (
      <Layout>
        <Main>
          <Section>
            <Container sx={{ px: [3, 3, 3, '50px'], py: 12 }}>
              <Flex sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
                <Box sx={{ width: ['full', '4/5', '3/4'] }}>
                  <Message sx={{ bg: 'red.1' }}>
                    No posts have been added yet
                  </Message>
                </Box>
              </Flex>
            </Container>
          </Section>
        </Main>
      </Layout>
    )
  }
  return (
    <Layout>
      <GatsbySeo
        title={RichText.asText(data.title)}
        titleTemplate="%s | ExesaLibero Pharma"
        description="ExesaLibero Pharma was formed to complete the preclinical toxicology and efficacy studies on a small molecule drug designed to control excess bone erosion associated with rheumatoid arthritis."
        openGraph={{
          images: [
            {
              url: `${image}`,
              width: 800,
              height: 600,
              alt: 'Og Image Alt',
            },
          ],
        }}
      />

      <Main>
        <Section id="company">
          <Container sx={{ px: '1em' }}>
            <Flex
              sx={{
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ width: ['full', 'full', '2/3'] }}>
                {data.date}
                <h1 sx={{ mt: 0 }}>{RichText.asText(data.title)}</h1>
                {RichText.render(data.content)}
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query PostQuery($uid: String!) {
    prismic {
      allPosts(lang: "en-us", uid: $uid) {
        edges {
          node {
            title
            date
            content
            _linkType
            _meta {
              id
              uid
            }
          }
        }
      }
    }
  }
`
